<template>
	<div>
		<b-modal size="xl" ref="modal" hide-header hide-footer>
			<div class="card">
				<div class="card-body">
					<div v-if="transactionSummary" class="row my-3 justify-content-center mb-5">
						<div class="col-10 align-self-center">
							<div class="text-center mb-4">
								<h2 class="mt-0">
									<i class="mdi mdi-check-all"></i>
								</h2>
								<p class="header-title text-muted">Transaction Summary Breakdown</p>
							</div>

							<b-row no-gutters>
								<b-col cols="12">
									<b-row class="border">
										<b-col cols="12" sm="6" class="mt-2">
											<span class="font-weight-bold"> Money In : </span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2 text-right">
											{{ transactionSummary ? formatAsMoney(transactionSummary.totalIn) : "" }}
										</b-col>
										<b-col cols="12" sm="6" class="mt-2">
											<span class="font-weight-bold"> Money Out : </span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2 text-right">
											{{ transactionSummary ? formatAsMoney(transactionSummary.totalOut) : "" }}
										</b-col>
										<b-col cols="12" sm="6" class="my-2">
											<span class="font-weight-bold"> Gross Profit : </span>
										</b-col>
										<b-col cols="12" sm="6" class="my-2 text-right">
											{{ transactionSummary ? formatAsMoney(transactionSummary.grossProfit) : "" }}
										</b-col>
									</b-row>

									<!--  Commission Calculations-->
									<b-row class="border mt-3">
										<b-col cols="12" class="pa-0 ma-0">
											<span class="font-weight-bold text-danger">Bar Commission:</span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2">
											<span class="font-weight-bold"> Gross Profit : </span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2 text-right">
											{{ transactionSummary ? formatAsMoney(transactionSummary.grossProfit) : "" }}
										</b-col>
										<b-col cols="12" sm="6" class="mt-2">
											<span class="font-weight-bold"> Commission Percent : </span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2 text-right">
											{{
												transactionSummary
													? " x " +
													  findPercentage(
															transactionSummary.commission,
															transactionSummary.grossProfit
													  ) +
													  "%"
													: ""
											}}
										</b-col>

										<b-col cols="12" sm="6" class="my-2">
											<span class="font-weight-bold"> Commission : </span>
										</b-col>
										<b-col cols="12" sm="6" class="my-2 text-right">
											{{ transactionSummary ? formatAsMoney(transactionSummary.commission) : "" }}
										</b-col>
									</b-row>

									<!------- Net Profit Calculation ---------->
									<b-row class="border mt-3">
										<b-col cols="12" class="pa-0 ma-0">
											<span class="font-weight-bold text-danger">Company Profit:</span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2">
											<span class="font-weight-bold"> Gross Profit : </span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2 text-right">
											{{ transactionSummary ? formatAsMoney(transactionSummary.grossProfit) : "" }}
										</b-col>

										<b-col cols="12" sm="6" class="mt-2">
											<span class="font-weight-bold"> Profit Percent: </span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2 text-right">
											{{
												transactionSummary
													? " x " +
													  findPercentage(
															transactionSummary.profits,
															transactionSummary.grossProfit
													  ) +
													  "%"
													: ""
											}}
										</b-col>

										<b-col cols="12" sm="6" class="my-2">
											<span class="font-weight-bold"> Profit : </span>
										</b-col>
										<b-col cols="12" sm="6" class="my-2 text-right">
											{{ transactionSummary ? formatAsMoney(transactionSummary.profits) : "" }}
										</b-col>
									</b-row>

									<!-------- Float to reinject Calculation----------->
									<b-row class="border mt-3">
										<b-col cols="12" sm="6" class="mt-2">
											<span class="font-weight-bold"> Float currently at location : </span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2 text-right">
											{{
												transactionSummary
													? formatAsMoney(transactionSummary.amountCurrentlyAtBar)
													: ""
											}}
										</b-col>

										<b-col cols="12" sm="6" class="mt-2">
											<span class="font-weight-bold"> Commission : </span>
										</b-col>
										<b-col cols="12" sm="6" class="mt-2 text-right">
											{{
												transactionSummary ? "+" + formatAsMoney(transactionSummary.commission) : ""
											}}
										</b-col>

										<b-col cols="12" sm="6" class="my-2">
											<span class="font-weight-bold"> Amount to be left at bar : </span>
										</b-col>
										<b-col cols="12" sm="6" class="my-2 text-right">
											{{
												transactionSummary ? formatAsMoney(transactionSummary.amountLeftAtBar) : ""
											}}
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
						<!-- end col -->
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	export default {
		data() {
			return {
				transactionSummary: null,
			};
		},
		methods: {
			openModal(viewTransaction) {
				this.transactionSummary = JSON.parse(JSON.stringify(viewTransaction));
				this.$refs["modal"].show();
			},
			closeModal() {
				this.$refs["modal"].hide();
				this.transactionSummary = null;
			},

			formatAsMoney(value) {
				return formatter.format(value);
			},

			findPercentage(partialValue, totalValue) {
				return ((100 * partialValue) / totalValue).toFixed(2);
			},
		},
	};
</script>
