<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div>
			<div class="row align-items-center">
				<div class="col-md-3 col-sm-6">
					<div class="form-group">
						<label for="branch">Please Select A Branch</label>
						<select class="form-control" name="branch" id="branch" v-model="selectedBranch">
							<template v-for="(branch, index) in branches">
								<option :key="index" :value="branch._id">
									{{ branch.name }}
								</option>
							</template>
						</select>
					</div>
				</div>
				<div class="col-md-3 col-sm-6">
					<div class="form-group">
						<label for="startDate">Date of meter reading</label>
						<date-picker
							value-type="YYYY-MM-DD"
							format="YYYY-MM-DD"
							v-model="startDate"
							name="startDate"
							id="startDate"
							type="date"
							lang="en"
							placeholder="Select A Date"
						/>
					</div>
				</div>
				<div class="col-md-3 col-sm-6">
					<div class="form-group">
						<label for="filterEndDate">End Date for filter</label>
						<date-picker
							value-type="YYYY-MM-DD"
							format="YYYY-MM-DD"
							v-model="filterEndDate"
							name="filterEndDate"
							id="filterEndDate"
							type="date"
							lang="en"
							placeholder="Select A Date"
						/>
					</div>
				</div>
				<div class="col-md-3 col-sm-6 text-right">
					<b-button @click="exportBranchTransactions" variant="outline-primary"
						>Export Transactions</b-button
					>
				</div>
			</div>
			<b-card>
				<ViewTransactionModal
					@refresh="reloadData"
					:singleTransactions="singleTransactions"
					ref="modal"
					:branch="selectedBranch"
					:startDate="startDate"
				/>
				<b-row>
					<b-col>
						<h4 class="header-title">Transactions for {{ currentBranch.name }}</h4>
					</b-col>
					<b-col class="text-right">
						<b-button to="/dashboard/super-admin/uploadTransaction" class="btn btn-success btn-sm"
							>Record Transaction</b-button
						>
					</b-col>
				</b-row>
				<p class="sub-header">A list of all the recent transactions</p>
				<b-table
					thead-class="red-t-head"
					outlined
					:per-page="perPage"
					:current-page="currentPage"
					hover
					:fields="headers"
					:items="transactions"
				>
					<template #cell(actions)="row">
						<div role="button" @click="viewTransaction(row.item)">
							<i class="mdi mdi-18px mdi-eye"></i>
						</div>
					</template>
				</b-table>
				<b-pagination
					:total-rows="transactions && transactions.length"
					:per-page="perPage"
					v-model="currentPage"
				></b-pagination>
			</b-card>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical";
	import PageHeader from "@/components/Page-header";
	import { mapActions, mapState } from "vuex";
	import ViewTransactionModal from "@/components/modals/ViewTransactionBreakdownModal.vue";
	import dayjs from "dayjs";
	import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
	dayjs.extend(isSameOrBefore);
	import DatePicker from "vue2-datepicker";
	var FileSaver = require("file-saver");
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	export default {
		components: {
			Layout,
			PageHeader,
			ViewTransactionModal,
			DatePicker,
		},
		data() {
			return {
				title: "Transactions",
				items: [
					{
						text: "Settings",
						active: true,
					},
				],
				//Transaction table headers
				currentPage: 1,
				perPage: 5,
				search: "",
				headers: [
					{
						label: "Total Money In",
						key: "totalIn",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
					{
						label: "Total Money Out",
						key: "totalOut",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
					{
						label: "Commision",
						key: "commission",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
					{
						label: "Amount Left At Bar",
						key: "amountLeftAtBar",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
					{
						label: "Profits",
						key: "profits",
						sortable: true,
						formatter: (v) => {
							return formatter.format(v);
						},
					},
					{
						label: "Actions",
						key: "actions",
					},
				],
				transactions: [],
				selectedBranch: null,
				startDate: null,
				filterEndDate: null,
				endDate: null,
				singleTransactions: [],
			};
		},
		watch: {
			selectedBranch: function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.loadTransactions();
				}
			},
			startDate: function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.loadTransactions();
				}
			},
			filterEndDate: function (newVal, oldVal) {
				this.loadTransactions();
			},
		},
		page: {
			title: "Transactions",
			meta: [
				{
					name: "description",
					content: "Latest Transactions are displayed here",
				},
			],
		},
		async created() {
			await this.getBranches();
			if (this.branches[0]) this.selectedBranch = this.branches[0]._id;
			this.startDate = dayjs().format("YYYY-MM-DD");
			this.filterEndDate = dayjs().add(2, "day").format("YYYY-MM-DD");
			this.loadTransactions();
		},
		computed: {
			...mapState("branchModule", ["branches"]),
			currentBranch() {
				return this.selectedBranch ? this.branches.find((b) => b._id == this.selectedBranch) : "";
			},
		},
		methods: {
			...mapActions("transactionsModule", [
				"getTransactions",
				"getTransactionSummaryDetails",
				"exportTransactions",
			]),
			...mapActions("branchModule", ["getBranches"]),
			async reloadData() {
				await this.loadTransactions();
				this.$refs["modal"].closeModal();
			},
			viewTransaction(transaction) {
				this.$refs["modal"].openModal(transaction);
			},
			/**
			 * Method used to export the transactions for the selected branch
			 */
			async exportBranchTransactions() {
				try {
					let res = await this.exportTransactions({
						branchId: this.selectedBranch,
						startDate: this.startDate,
						endDate: this.filterEndDate,
					});
					const blob = new Blob([res.data], { type: "text/csv;charset=utf-8" });
					FileSaver.saveAs(blob, "transactions.csv");
				} catch (error) {
					console.log(error);
				}
			},

			async loadTransactions() {
				try {
					const { data } = await this.getTransactionSummaryDetails({
						branchId: this.selectedBranch,
						startDate: this.startDate,
						endDate: this.filterEndDate,
					});
					this.transactions = data;
					// 	.filter((t) => {
					// 	const readingDate = dayjs(t._id.readingDate);
					// 	const now = dayjs();
					// 	return readingDate.isSameOrBefore(now);
					// 	// t._id.readingDate == this.startDate
					// });
					this.loadSingleTransactions();
				} catch (error) {
					console.log(error);
				}
			},
			/**
			 * Load all the transactions for the selected date
			 */
			async loadSingleTransactions() {
				try {
					const result = await this.getTransactions({
						branchId: this.selectedBranch,
						startDate: this.startDate,
						endDate: this.filterEndDate,
					});

					const { data } = result;
					if (Array.isArray(data.results)) {
						const { results } = data;
						this.singleTransactions = results.filter((r) => r.readingType != "FLOAT-REINJECTION");
					}
				} catch (error) {
					console.log(error);
				}
			},
		},
	};
</script>

<style scoped></style>
