<template>
	<div>
		<b-modal size="xl" ref="modal" hide-header hide-footer @ok="confirm">
			<div class="card">
				<div class="card-body">
					<div class="text-center mb-4">
						<i class="bx bxs-info-circle h1 text-info"></i>
						<h4 class="mt-0">Transaction Summary</h4>
						<b-button
							@click="$refs.transactionSummaryModal.openModal(viewTransaction)"
							variant="outline-success"
							size="sm"
							>View Summary</b-button
						>
					</div>
					<b-table responsive striped hover :fields="headers" :items="singleTransactions">
						<template #cell(actions)="row">
							<div>
								<span
									v-b-modal="'edit-transaction-modal'"
									class=""
									role="button"
									@click="setEdit(row.item)"
								>
									<i class="mdi mdi-18px mdi-pencil"></i>
								</span>
								<span
									class="ml-2"
									role="button"
									v-b-modal="'delete-transaction-modal'"
									@click="deleteItem = row.item"
								>
									<i class="mdi mdi-18px mdi-delete"></i>
								</span>
							</div>
						</template>
					</b-table>
				</div>
			</div>
		</b-modal>
		<b-modal
			@ok="delTransaction"
			ok-title="Confirm"
			ok-variant="success"
			id="delete-transaction-modal"
			title="Are you sure you want to delete the transaction?"
		>
			<p>Once deleted, the transaction cannot be recovered.</p>
		</b-modal>
		<b-modal
			@ok="updateTransactionItem"
			ok-title="Update"
			ok-variant="success"
			id="edit-transaction-modal"
			title="Edit Transaction"
		>
			<b-container>
				<b-form>
					<b-row>
						<b-col cols="12">
							<b-form-group label="Reading In">
								<b-form-input required min="0" type="number" v-model.number="editItem.readingIn" />
							</b-form-group>
						</b-col>
						<b-col cols="12">
							<b-form-group label="Reading Out">
								<b-form-input required min="0" type="number" v-model.number="editItem.readingOut" />
							</b-form-group>
						</b-col>
						<b-col cols="12">
							<b-form-group label="Comment">
								<b-form-textarea rows="3" v-model.number="editItem.comments" />
							</b-form-group>
						</b-col>
					</b-row>
				</b-form>
			</b-container>
		</b-modal>
		<TransactionSummaryModal ref="transactionSummaryModal" />
	</div>
</template>

<script>
	import TransactionSummaryModal from "@/components/modals/TransactionSummaryModal.vue";
	import { mapActions } from "vuex";
	import _ from "lodash";
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	import dayjs from "dayjs";
	export default {
		components: { TransactionSummaryModal },
		props: {
			singleTransactions: Array,
			branch: String,
			startDate: String,
		},
		data() {
			return {
				editItem: {},
				deleteItem: {},
				viewTransaction: {},
				headers: [
					{ key: "gamebox.serialNumber", label: "Serial Number" },
					{ key: "gamebox.name", label: "Machine Name" },
					{ key: "game.name", label: "Game Name" },
					{
						key: "transactionDate",
						label: "Transaction Date",
						formatter: (v) => {
							return dayjs(v).format("MMM DD, YYYY");
						},
					},
					{
						key: "readingIn",
						label: "Reading In",
						sortable: true,
					},
					{ key: "readingOut", label: "Reading Out", sortable: true },
					{
						key: "totalIn",
						label: "Money In",
						sortable: true,
						formatter: (v, k, i) => {
							return formatter.format(v);
						},
					},
					{
						key: "totalOut",
						label: "Money Out",
						sortable: true,
						formatter: (v, k, i) => {
							return formatter.format(v);
						},
					},
					{
						key: "actions",
						label: "Actions",
					},
				],
			};
		},
		methods: {
			...mapActions("transactionsModule", [
				"deleteTransaction",
				"updateTransaction",
				"getTransactions",
				"getTransactionSummaryDetails",
			]),
			openModal(transaction) {
				this.viewTransaction = _.cloneDeep(transaction);
				this.$refs["modal"].show();
			},
			closeModal() {
				this.$refs["modal"].hide();
			},
			formatAsMoney(value) {
				return formatter.format(value);
			},
			confirm() {
				this.$nextTick(() => {
					this.$refs["modal"].hide();
				});
			},
			setEdit(item) {
				this.editItem = _.cloneDeep(item);
			},
			async delTransaction() {
				if (!this.deleteItem || !this.deleteItem._id) return;
				try {
					await this.deleteTransaction(this.deleteItem._id);
					this.$bvToast.toast("Transaction Deleted", {
						title: "Success",
						autoHideDelay: 5000,
						variant: "success",
					});
					this.$emit("refresh");
				} catch (error) {
					console.log(error);
				}
			},
			async updateTransactionItem() {
				if (!this.editItem || !this.editItem._id) return;
				try {
					await this.updateTransaction({
						_id: this.editItem._id,
						readingIn: Number(this.editItem.readingIn),
						readingOut: Number(this.editItem.readingOut),
						transactionDate: this.editItem.transactionDate,
						comments: this.editItem.comments || null,
					});
					this.$bvToast.toast("Transaction Updated", {
						title: "Success",
						autoHideDelay: 5000,
						variant: "success",
					});
					// this.$emit("refresh");
					// this.$bvModal.hide("edit-transaction-modal");

					// Get fresh summary data from DB
					const { data } = await this.getTransactionSummaryDetails({
						branchId: this.branch,
						startDate: this.startDate,
					});
					this.viewTransaction = data.filter((t) => t._id.readingDate == this.startDate)[0] || {};
					// display the summary
					this.$refs.transactionSummaryModal.openModal(this.viewTransaction);
				} catch (error) {
					console.log(error);
				}
			},
		},
	};
</script>
